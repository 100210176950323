<div class="d-inline-block position-relative">
  @if (filters && filters.valid && filters.value.length > 0) {
    <div class="counter-filter-badge">
      <span>{{ filters.value.length }}</span>
    </div>
  }

  @if (!isMobile) {
    <mat-chip
      class="wsm-mat-chip d-inline-flex"
      #filterMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="btnFilterMenu"
      [disabled]="disabled"
    >
      <i class="fa-regular fa-filter me-1"></i>
      {{ "shared.components.overlay_filter.filter" | translate }}
    </mat-chip>
    <mat-menu #btnFilterMenu="matMenu" class="custom-filter-menu">
      <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
          <ng-template [ngTemplateOutlet]="formFilter"></ng-template>
        </div>
      </ng-template>
    </mat-menu>
  }
  @if (isMobile) {
    <mat-chip
      (click)="openSheet()"
      class="wsm-mat-chip d-inline-flex"
      [disabled]="disabled"
    >
      <i class="fa-regular fa-filter me-1"></i>
      {{ "shared.components.overlay_filter.filter" | translate }}
    </mat-chip>
  }
</div>

<ng-template #formFilter>
  <form [formGroup]="filterForm" class="container filter-form-container">
    <ng-container formArrayName="filters">
      @for (filter of filters.controls; track filter; let i = $index) {
        <form
          [ngClass]="{'flex-column': isMobile}"
          [formGroup]="filter | formGroup"
          class="row mt-2"
        >
          <!-- Field -->
          <div [ngClass]="{'mb-2': isMobile}" class="col-lg-4 col-sm-12">
            <label>{{
              "shared.components.overlay_filter.choose_field_label" | translate
            }}</label>
            <mat-select
              formControlName="field"
              [disableOptionCentering]="true"
              class="wsm-input w-100 validate"
              [panelClass]="'wsm-custom-select'"
              placeholder="{{
                'shared.components.overlay_filter.choose_field_placeholder'
                  | translate
              }}"
              (selectionChange)="validateOperatorList($event, i)"
            >
              @for (field of fieldList; track field) {
                <mat-option [value]="field">{{
                  field.keyLabelTranslate | translate
                }}</mat-option>
              }
            </mat-select>
            @if (
              filter.get("field")?.errors?.["required"] &&
              filter.get("field")?.dirty
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
          <!-- Operator -->
          <div [ngClass]="{'mb-2': isMobile}" class="col-lg-3 col-sm-12">
            <label>{{
              "shared.components.overlay_filter.operator_label" | translate
            }}</label>
            <mat-select
              formControlName="operator"
              [disableOptionCentering]="true"
              class="wsm-input w-100 validate"
              [panelClass]="'wsm-custom-select'"
              placeholder="{{
                'shared.components.overlay_filter.operator_placeholder'
                  | translate
              }}"
            >
              @for (op of operatorListToggle[i]; track op) {
                @if (op.show) {
                  <mat-option [value]="op.operatorValue">{{
                    op.operatorLabelTranslate | translate
                  }}</mat-option>
                }
              }
            </mat-select>
            @if (
              filter.get("operator")?.errors?.["required"] &&
              filter.get("operator")?.dirty
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
          <!-- Value -->
          <div class="col-lg-4 col-sm-12">
            <label>{{
              "shared.components.overlay_filter.value_label" | translate
            }}</label>
            @if (filter.get("field") && filter.get("field")?.value) {
              <!--DATE INPUT-->
              @if (
                filter.get("field") &&
                filter.get("field")?.value?.operatorType === "date"
              ) {
                <input
                  formControlName="value"
                  class="wsm-input w-100 validate"
                  placeholder="{{
                    'shared.components.overlay_filter.value_placeholder'
                      | translate
                  }}"
                  (click)="datePicker.open()"
                  [matDatepicker]="datePicker"
                />
                <mat-datepicker #datePicker></mat-datepicker>
                @if (
                  filter.get("value")?.errors?.["required"] &&
                  filter.get("value")?.dirty
                ) {
                  <div class="mt-1">
                    <span class="invalid-info">{{
                      "common.errors.field_required" | translate
                    }}</span>
                  </div>
                }
              }
              <!--TEXT INPUT-->
              @if (
                (filter.get("field") &&
                  filter.get("field")?.value?.operatorType === "string") ||
                filter.get("field")?.value.operatorType === "number"
              ) {
                <input
                  formControlName="value"
                  type="text"
                  class="wsm-input w-100 validate"
                  placeholder="{{
                    'shared.components.overlay_filter.value_placeholder'
                      | translate
                  }}"
                />
                @if (
                  filter.get("value")?.errors?.["required"] &&
                  filter.get("value")?.dirty
                ) {
                  <div class="mt-1">
                    <span class="invalid-info">{{
                      "common.errors.field_required" | translate
                    }}</span>
                  </div>
                }
              }
            } @else {
              <div class="wsm-input ghost w-100">
                <span>{{
                  "shared.components.overlay_filter.input_ghost" | translate
                }}</span>
              </div>
            }
            <!--USER INPUT-->
            @if (
              filter.get("field") &&
              filter.get("field")?.value?.operatorType === "user"
            ) {
              <div class="wsm-input-group-prepend w-100">
                <i
                  [ngClass]="{'d-none': !usersLoading}"
                  class="fa-solid fa-circle-notch fa-spin wsm-text-white ac-loading"
                ></i>
                <input
                  type="text"
                  class="wsm-input w-100 validate"
                  placeholder="{{
                    'shared.components.overlay_filter.value_placeholder'
                      | translate
                  }}"
                  formControlName="value"
                  [matAutocomplete]="ownerAutocomplete"
                />
                <mat-autocomplete
                  #ownerAutocomplete="matAutocomplete"
                  [displayWith]="displayUserFn"
                >
                  @for (option of filteredOptions[i] | async; track option) {
                    <mat-option
                      [value]="option | as: UserModel"
                      [innerHtml]="
                        (option | as: UserModel).name! | highlight: toHighlight
                      "
                    >
                    </mat-option>
                  }
                </mat-autocomplete>
              </div>
              @if (
                filter.get("value")?.errors?.["required"] &&
                filter.get("value")?.dirty
              ) {
                <div class="mt-1">
                  <span class="invalid-info">{{
                    "common.errors.field_required" | translate
                  }}</span>
                </div>
              }
            }
            <!--LIST-->
            @if (
              filter.get("field") &&
              filter.get("field")?.value?.operatorType === "list"
            ) {
              <mat-select
                formControlName="value"
                [disableOptionCentering]="true"
                class="wsm-input w-100 validate"
                [panelClass]="'wsm-custom-select'"
                placeholder="{{
                  'shared.components.overlay_filter.value_placeholder'
                    | translate
                }}"
              >
                @for (item of filter.get("field")?.value?.list; track item) {
                  <mat-option [value]="item">{{
                    item.labelTranslate | translate
                  }}</mat-option>
                }
              </mat-select>
              @if (
                filter.get("value")?.errors?.["required"] &&
                filter.get("value")?.dirty
              ) {
                <div class="mt-1">
                  <span class="invalid-info">{{
                    "common.errors.field_required" | translate
                  }}</span>
                </div>
              }
            }
          </div>
          <!-- Remove -->
          <div
            [ngClass]="{'align-self-end': isMobile}"
            class="col-1 d-flex align-items-end justify-content-center"
          >
            <button
              class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
              (click)="$event.preventDefault(); removeFilter(i)"
            >
              <i class="fa-regular fa-trash"></i>
            </button>
          </div>
          @if (i !== filters.length - 1) {
            <hr [ngClass]="{'mt-4': i === 0}" class="mt-1" />
          }
        </form>
      }
    </ng-container>
    <div class="row mt-3">
      <div class="d-flex flex-column">
        <button
          class="wsm-btn wsm-btn-link align-self-start"
          (click)="addFilter()"
        >
          <i class="fa-regular fa-plus"></i>
          {{ "shared.components.overlay_filter.add_filter" | translate }}
        </button>
        <div class="d-flex gap-2 align-self-end">
          <button
            class="wsm-btn wsm-btn-outline wsm-btn-small"
            (click)="onClearFilters()"
          >
            {{ "shared.components.overlay_filter.clear_filter" | translate }}
          </button>
          <button
            class="wsm-btn wsm-btn-primary wsm-btn-small"
            (click)="onSaveFilters()"
          >
            {{ "shared.components.overlay_filter.apply_button" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
