<div
  class="container-fluid d-flex align-items-center justify-content-between profile-container"
>
  <div class="burger-container">
    @if (burgerMenu) {
      @if (!isMobile) {
        <i class="fa-regular fa-bars me-3 mt-1" (click)="showSidenav()"> </i>
      }
      @if (orgLogo) {
        <div
          class="org-logo-container p-2"
          [ngClass]="{'dark-mode': isDarkTheme}"
          id="navbarOrgLogo"
        >
          <img
            class="org-logo"
            [src]="orgLogo"
            alt="Logo"
            routerLink="home"
          />
        </div>
      }
      @if (!orgLogo && !squareLogo) {
        @if (isDarkTheme) {
          <img
            src="assets/img/svg/logo-with-text.svg"
            class="logo-with-text"
            alt="Logo"
            routerLink="home"
          />
        }
        @if (!isDarkTheme) {
          <img
            src="assets/img/svg/logo-with-text-mobile.svg"
            alt="Logo"
            routerLink="home"
          />
        }
      }
    }
  </div>

  <div class="profile-navbar">
    @if (billingInfo === "PASTDUE") {
      <div class="warning-container me-3">
        <i class="fa-regular fa-triangle-exclamation warning-icon me-2"></i>
        <a
          class="warning-message"
          matTooltip="{{
            'common.warning_tooltips.expired_tooltip' | translate
          }}"
          matTooltipPosition="below"
          matTooltipClass="wsm-tooltip plan-tooltip tooltip-below"
          [routerLink]="'settings/billing'"
        >
          {{ "common.plan_warnings.expired" | translate }}
        </a>
      </div>
    }

    @if (!isMobile) {
      <span class="me-3">{{ user?.name }}</span>
    }
    <div
      [style.background-color]="
        user?.avatarBackground ? user.avatarBackground : '#fc7282'
      "
      class="profile-picture"
    >
      <img
        [src]="profilePicture"
        [ngClass]="user?.image && user?.picture ? 'image' : 'avatar'"
        alt="Profile Picture"
        class="profile-picture animate__animated animate__fadeIn"
      />
    </div>

    <button
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon ms-1"
      [matMenuTriggerFor]="navbarOptions"
    >
      <i class="fa-regular fa-chevron-down"></i>
    </button>

    <mat-menu #navbarOptions="matMenu">
      @if (!isExternalUser) {
        <button
          mat-menu-item
          class="justify-content-end"
          [routerLink]="['settings/profile-settings']"
        >
          {{ "profileNavbar.profileSettings" | translate }}
        </button>
      }

      <button
        mat-menu-item
        class="justify-content-end dark-mode-item"
        (click)="$event.stopPropagation()"
      >
        <mat-select
          class="wsm-input mat-lang w-100"
          disableOptionCentering
          [(ngModel)]="currentLang"
          (selectionChange)="changeSelectedLanguage($event)"
          [panelClass]="'wsm-custom-select'"
        >
          <mat-select-trigger>
            {{ "profileNavbar.language" | translate }}:
            {{ currentLang | uppercase }}
          </mat-select-trigger>
          @for (language of languages; track language) {
            <mat-option [value]="language">
              {{ language | uppercase }}
            </mat-option>
          }
        </mat-select>
      </button>

      @if (canSwitchTo) {
        @if (callImpersonate && !isMobile) {
          <button
            mat-menu-item
            class="justify-content-end"
            (click)="onSwitch()"
          >
            {{ "profileNavbar.switch_to" | translate }}
          </button>
        }
      }
      @if (!isMobile) {
        <button
          mat-menu-item
          class="justify-content-end"
          (click)="goToGetStarted()"
        >
          {{ "get_started.relaunch_cta" | translate }}
        </button>
      }
      <button
        mat-menu-item
        class="justify-content-end dark-mode-item"
        (click)="$event.stopPropagation()"
      >
        <mat-slide-toggle
          class="custom-toggle w-100"
          [checked]="isDarkTheme"
          (change)="toggleTheme($event)"
        >
          <span class="ms-3">{{ "profileNavbar.darkMode" | translate }}</span>
        </mat-slide-toggle>
      </button>

      @if (!user?.isSupport) {
        <button mat-menu-item class="justify-content-end" (click)="logout()">
          {{ "profileNavbar.logout" | translate }}
        </button>
      }
      @if (user?.isSupport) {
        <button
          mat-menu-item
          class="justify-content-end"
          (click)="logoutFromImpersonate()"
        >
          {{ "profileNavbar.logout" | translate }}
        </button>
      }
    </mat-menu>
  </div>
</div>
