<div #accountCard class="service-account-card h-100 p-3">
  @if (isIndividual && !isMobile) {
    <div class="mb-2 text-end">
      <mat-slide-toggle
        (change)="onChangeStatus($event)"
        [(ngModel)]="account.selected"
      >
        {{
          (account.selected
            ? "company.company_connections.linked_label"
            : "company.company_connections.unlinked_label"
          ) | translate
        }}
      </mat-slide-toggle>
    </div>
  }

  <div class="d-flex flex-row flex-lg-column">
    <div
      class="p-2 p-lg-4 me-3 me-lg-0 mb-3"
      [ngClass]="{
        'mobile-logo-container': isMobile,
        'logo-container': !isMobile,
      }"
      [ngStyle]="{'opacity': isIndividual ? (account.selected ? 1 : 0.4) : 1}"
    >
      <img
        [src]="logoSrc"
        class="connection-logo"
        [ngClass]="{'mobile-connection-logo': isMobile}"
        title="{{ account.type }}"
        alt="Logo"
      />
    </div>

    <div>
      <div
        class="mb-3"
        [ngStyle]="{'opacity': isIndividual ? (account.selected ? 1 : 0.4) : 1}"
      >
        <label class="m-0" [ngClass]="{'card-info-title': isMobile}">{{
          accountLabel
        }}</label>
        <p
          class="wsm-text-grayed text-break"
          [ngClass]="{'card-info-subtitle': isMobile}"
          title="{{ account.name }}"
        >
          {{
            account.name !== ""
              ? account.name
              : ("connections.accounts.no_set_label" | translate)
          }}
        </p>
      </div>

      <div
        [ngStyle]="{'opacity': isIndividual ? (account.selected ? 1 : 0.4) : 1}"
      >
        <label class="m-0" [ngClass]="{'card-info-title': isMobile}">{{
          idLabel
        }}</label>
        <p
          class="wsm-text-grayed text-break"
          [ngClass]="{'card-info-subtitle': isMobile}"
          title="{{ account.accountId }}"
        >
          {{ account.accountId }}
        </p>
      </div>

      @if (isIndividual && isMobile) {
        <div class="mt-2">
          <mat-slide-toggle
            (change)="onChangeStatus($event)"
            [(ngModel)]="account.selected"
          >
            {{
              (account.selected
                ? "company.company_connections.linked_label"
                : "company.company_connections.unlinked_label"
              ) | translate
            }}
          </mat-slide-toggle>
        </div>
      }
    </div>
  </div>

  @if (!isIndividual && isEditMode) {
    <button
      (click)="removeServiceAccount()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon btn-delete"
    >
      <i class="fa-solid fa-close"></i>
    </button>
  }
  <div
    class="left-partial-border"
    [style.background-color]="borderColor"
    [ngStyle]="{'opacity': isIndividual ? (account.selected ? 1 : 0.4) : 1}"
  ></div>
</div>
