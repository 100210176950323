import {DatePipe, NgTemplateOutlet, NgClass, AsyncPipe} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import {MatSelectChange, MatSelect} from '@angular/material/select';
import moment from 'moment';
import {
  Observable,
  Subscription,
  catchError,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {ApiService} from 'src/app/core/services';
import {
  FilterFieldItem,
  FilterOperatorItem,
  FilterRow,
  OperatorType,
  User,
} from '../../models';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatChip} from '@angular/material/chips';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TranslateModule} from '@ngx-translate/core';
import {HighlightTextPipe} from '../../pipes';
import {FormGroupPipe} from '../../pipes';
import {AsPipe} from '../../pipes';

interface FilterOperationItemToggle extends Required<FilterOperatorItem> {
  show: boolean;
}

export const customFormatDate = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-filter-overlay-menu',
  templateUrl: './filter-overlay-menu.component.html',
  styleUrls: ['./filter-overlay-menu.component.scss'],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: customFormatDate},
  ],
  standalone: true,
  imports: [
    MatChip,
    MatMenuModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatSelect,
    NgxIntlTelInputModule,
    MatOption,
    MatDatepickerModule,
    MatAutocompleteModule,
    AsyncPipe,
    TranslateModule,
    HighlightTextPipe,
    FormGroupPipe,
    AsPipe,
  ],
})
export class FilterOverlayMenuComponent implements OnInit {
  @Input() fieldList: Array<FilterFieldItem>;
  @Input() disabled: boolean;
  @Input() operatorList: Array<FilterOperatorItem>;
  @Input() isMobile = false;
  @Output() selectedFilters = new EventEmitter<Array<FilterRow>>();

  @ViewChild('filterMenuTrigger') filterMenuTrigger: MatMenuTrigger;
  @ViewChild('formFilter') formFilter: TemplateRef<any>;

  public filterForm: FormGroup;
  public showOperator: boolean;
  public operatorListToggle: Array<Array<FilterOperationItemToggle>> = [];
  public filteredOptions: Observable<User[]>[] = [];
  public UserModel!: User;
  public toHighlight: string;

  public subs: Subscription = new Subscription();

  // loadings
  public usersLoading: boolean;
  public userNoResult: boolean;

  constructor(
    private apiService: ApiService,
    readonly bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.addFilter();
  }

  private addFieldListToggle(): void {
    const list = [...this.operatorList] as Array<FilterOperationItemToggle>;
    this.operatorListToggle.push(list);
  }

  private buildForm(): void {
    this.filterForm = new FormGroup({
      filters: new FormArray([]),
    });
  }

  addFilter(filterRow?: FilterRow): void {
    const filter = this.createFilter(filterRow);
    (this.filterForm.get('filters') as FormArray).push(filter);
    this.addFieldListToggle();
    this.setFilteredOptions(filter);
  }

  private createFilter(filterRow?: FilterRow): FormGroup {
    return new FormGroup({
      field: new FormControl(filterRow?.field, Validators.required),
      operator: new FormControl(filterRow?.operator, Validators.required),
      value: new FormControl(filterRow?.value, Validators.required),
    });
  }

  removeFilter(index: number): void {
    this.filters.removeAt(index);
    this.operatorListToggle.splice(index, 1);
    this.filterFilteredOptions[index] &&
      this.filterFilteredOptions[index]?.unsubscribe();
  }

  onSaveFilters(): void {
    if (this.filters.invalid) {
      this.validateFilterRowControls();
    }
    if (this.filters.valid) {
      const filtersToSend = (this.filters.value as Array<FilterRow>).map(
        (f) => {
          let newValue: string;

          switch (f.field.operatorType) {
            case 'date':
              newValue = moment(f.value).format('YYYY-MM-DD');
              break;
            case 'user':
              newValue = f.value.id;
              break;
            case 'list':
              newValue = f.value.value;
              break;
            default:
              newValue = f.value;
              break;
          }
          return {...f, value: newValue};
        }
      );

      this.selectedFilters.emit(filtersToSend);
      !this.isMobile
        ? this.filterMenuTrigger.closeMenu()
        : this.bottomSheet.dismiss();
    }
  }

  private validateFilterRowControls(): void {
    const rows = this.filters.controls as Array<FormGroup>;
    rows.forEach((row) => {
      row.controls['field'].markAsDirty();
      row.controls['operator'].markAsDirty();
      row.controls['value'].markAsDirty();
    });
  }

  /**
   * remove all filters except the first one
   */
  onClearFilters(): void {
    if (this.filters.length > 0) {
      this.filters.clear();
    }
    this.selectedFilters.emit([]);
    this.filterMenuTrigger.closeMenu();
  }

  private setFilteredOptions(filterRow: FormGroup): void {
    this.filteredOptions[this.filters.length - 1] = filterRow
      .get('value')
      ?.valueChanges.pipe(
        filter((val) => typeof val === 'string'),
        tap(() => {
          if (filterRow.get('field') && filterRow.get('field')?.value) {
            const rowType = filterRow.get('field')?.value
              .operatorType as OperatorType;
            switch (rowType) {
              case 'user':
                this.usersLoading = true;
                break;
              default:
                this.removeLoading();
                break;
            }
          }
        }),
        switchMap((val: string): Observable<User[]> => {
          const rowType = filterRow.get('field')?.value
            .operatorType as OperatorType;
          if (val.trim() === '') {
            this.removeLoading();
            return of([] as Array<User>);
          }
          return this.filterFilteredOptions(val, rowType) || of([]);
        }),
        catchError((error) => {
          this.removeLoading();
          return of([] as Array<User>);
        })
      ) as Observable<Array<User>>;
  }

  private filterFilteredOptions(
    val: string,
    type: OperatorType
  ): Observable<Array<User>> | undefined {
    this.toHighlight = val;
    switch (type) {
      case 'user':
        return this.apiService.getUsersTypeAhead(val, true).pipe(
          map((response) => {
            this.usersLoading = false;
            this.userNoResult = response.length === 0;
            return response.filter((option) =>
              option.name!.toLowerCase().includes(val.toLocaleLowerCase())
            );
          })
        );
    }
  }

  displayUserFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private removeLoading(): void {
    this.usersLoading = false;
  }

  validateOperatorList(event: MatSelectChange, indexList: number): void {
    const field = event.value as FilterFieldItem;
    const matchList = [...this.operatorListToggle[indexList]];

    const updateOperatorList = matchList.reduce((acc, value) => {
      const newOperator = {
        ...value,
        show: value.operatorTypes.includes(field.operatorType),
      } as FilterOperationItemToggle;

      acc = [...acc, newOperator];
      return acc;
    }, [] as Array<FilterOperationItemToggle>);

    this.operatorListToggle[indexList] = [...updateOperatorList];
  }

  openSheet(): void {
    this.bottomSheet.open(this.formFilter, {
      hasBackdrop: true,
      closeOnNavigation: true,
    });
  }

  get filters(): FormArray {
    return this.filterForm.controls['filters'] as FormArray;
  }
}
