@if (mediumButton()) {
  <button
    type="button"
    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
    [matMenuTriggerFor]="menu"
    [ngClass]="customClass()"
  >
    <i class="fa-regular fa-circle-question"></i>
  </button>
} @else if (bigButton()) {
  <button
    class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    matTooltip="{{ tooltipText() | translate }}"
    data-cy-presentationModebtn="presentation-mode-btn"
    matTooltipPosition="below"
    matTooltipClass="wsm-tooltip tooltip-below"
    [matMenuTriggerFor]="menu"
  >
    <i class="fa-regular fa-circle-question"></i>
  </button>
} @else {
  <button
    type="button"
    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon btn-custom-tiny me-1"
    [matMenuTriggerFor]="menu"
    [ngClass]="customClass()"
  >
    <i class="fa-regular fa-circle-question"></i>
  </button>
}

<mat-menu #menu="matMenu" [class]="'custom-help-menu'">
  <div class="help-content">
    <p class="wsm-text-grayed">{{ helpTextTranslation() | translate }}</p>

    <div class="actions">
      <button class="wsm-btn wsm-btn-link" (click)="onClosse()">
        {{ "userflow.close" | translate }}
      </button>

      <button class="wsm-btn wsm-btn-link" (click)="onLaunchHelp()">
        {{ "userflow.take_tour" | translate }}
      </button>
    </div>
  </div>
</mat-menu>
