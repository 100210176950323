@if (!isPrint) {
  <div
    class="d-flex justify-content-between"
    [ngClass]="{
      'flex-column': isMobile && !largeMobile,
      'mt-2': !isExternalUser || isMobile,
    }"
  >
    <app-report-page-filters
      [page]="page"
      [isLoading]="isLoading"
      [isMobile]="isMobile"
      (bulkDeleteWidgets)="saveDeleteWidgets(true)"
    />
    <app-date-picker
      [isEditMode]="false"
      [dateRange]="page.dateRange"
      [origin]="'page'"
      [isDisabled]="isLoading"
      [isMobile]="isMobile"
      [largeMobile]="largeMobile"
      (rangeSelection)="onDateSelection($event)"
    />
  </div>
}

@if (!isMobile) {
  <div
    id="report-page-paste"
    class="reportPageCanvas"
    [cdkContextMenuTriggerFor]="pageMenu"
    [cdkContextMenuDisabled]="!isEditMode"
    [ngClass]="{'m-auto': orientation === 'vertical'}"
    [ngStyle]="{'width': orientation === 'vertical' ? '768px' : '100%'}"
  >
    <app-report-grid
      id="report-grid"
      [orientation]="orientation"
      [theme]="theme"
      [bckImageUrl]="bckImageUrl"
      [isEditMode]="isEditMode"
      [page]="page"
      [pages]="pages"
      [reportId]="reportId"
      [isLoading]="isLoading"
      [isPrint]="isPrint"
    />
  </div>
} @else {
  <app-mobile-grid
    [theme]="theme"
    [isEditMode]="isEditMode"
    [page]="page"
    [pages]="pages"
    [reportId]="reportId"
    [isLoading]="isLoading"
    [isPrint]="isPrint"
  />
}

<!-- Context Menu -->
<ng-template #pageMenu>
  <div class="ac-menu" cdkMenu id="pageContextMenu">
    <!-- Paste -->
    @if (!isExternalUser) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="handleContextMenuAction('page', 'paste')"
      >
        <span class="text">
          <i class="fa-regular fa-clipboard icon"></i>
          {{ "context_menu.paste" | translate }}
        </span>
        @if (!isSafariBrowser) {
          <span class="shortcut"> Ctrl + V </span>
        }
      </button>
    }
    <!-- Duplicate -->
    @if (!isExternalUser) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="handleContextMenuAction('page', 'duplicate')"
      >
        <span class="text">
          <i class="fa-regular fa-copy icon"></i>
          {{ "context_menu.duplicate" | translate }}
        </span>
      </button>
    }
    <!-- Hide / Show -->
    <button
      class="ac-menu-item"
      cdkMenuItem
      (click)="handleContextMenuAction('page', 'showHide')"
    >
      <span class="text">
        <i class="fa-regular fa-eye-slash icon"></i>
        {{
          (page.isVisible ? "context_menu.hide" : "context_menu.show")
            | translate
        }}
      </span>
    </button>
    <!-- Delete -->
    @if (pages?.length !== 1 && !isExternalUser) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="handleContextMenuAction('page', 'delete')"
      >
        <span class="text">
          <i class="fa-regular fa-trash icon"></i>
          {{ "context_menu.delete" | translate }}
        </span>
      </button>
    }
    <!-- Save Template -->
    @if (!isExternalUser) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="handleContextMenuAction('page', 'library')"
      >
        <span class="text">
          <i class="fa-regular fa-book-open-reader icon"></i>
          {{ "context_menu.library" | translate }}
        </span>
      </button>
    }
  </div>
</ng-template>
