@if (!showBurgerMenu) {
  <section
    class="wsm-sidenav"
    (mouseenter)="toggleCollapse('enter')"
    (mouseleave)="toggleCollapse('leave')"
    [ngClass]="{'wsm-sidenav-collapsed': collapsed}"
    @fadeInOut
  >
    <div class="logo-container flex-column gap-2">
      @if (!collapsed) {
        <img
          [class]="
            squareLogo &&
            (userPlan === 'Premium' ||
              userPlan === 'Agency' ||
              userPlan === 'Enterprise' ||
              userPlan === 'Free')
              ? 'd-block'
              : 'd-none'
          "
          class="scale-in-center square-logo"
          [src]="squareLogo"
          alt="Logo"
          id="not-collapsed-org-logo"
        />

        <img
          [class]="
            (!orgLogo && !squareLogo) ||
            (userPlan !== 'Premium' &&
              userPlan !== 'Agency' &&
              userPlan !== 'Enterprise' &&
              userPlan !== 'Free')
              ? 'd-block'
              : 'd-none'
          "
          class="scale-in-center square-logo"
          [src]="'assets/img/svg/logo.svg'"
          alt="Logo"
          id="not-collapsed-default-logo"
        />
      }
      @if (collapsed) {
        <img
          [class]="
            orgLogo &&
            (userPlan === 'Premium' ||
              userPlan === 'Agency' ||
              userPlan === 'Enterprise' ||
              userPlan === 'Free')
              ? 'd-block'
              : 'd-none'
          "
          [src]="orgLogo"
          @fadeInOut
          class="org-logo"
          alt="Logo"
          id="collapsed-org-logo"
        />

        <img
          [class]="
            (!orgLogo && !squareLogo) ||
            (userPlan !== 'Premium' &&
              userPlan !== 'Agency' &&
              userPlan !== 'Enterprise' &&
              userPlan !== 'Free')
              ? 'd-block'
              : 'd-none'
          "
          [src]="'assets/img/svg/logo-with-text.svg'"
          @fadeInOut
          class="org-logo"
          alt="Logo"
          id="collapsed-default-logo"
        />
      }
      @if (isDev) {
        <span class="wsm-tag success"> DEV </span>
      }
      @if (isLocal) {
        <span class="wsm-tag warning"> LOCAL </span>
      }
    </div>
    <ul class="wsm-sidenav-nav" data-cy-sidenavBar="sidenav-bar">
      @for (link of sidenavLinks; track link.path) {
        <li class="sidenav-nav-item" [id]="link?.id">
          @if (authService.checkRole(link.roles)) {
            <a
              routerLink="{{ link.path }}"
              [fragment]="link?.fragment"
              routerLinkActive="active-sidenav-link"
              class="sidenav-nav-link"
              (click)="hideSidenav()"
              data-cy-sidenavLink="sidenavLink"
            >
              @if (link?.isBeta) {
                <span class="beta-link">
                  <i class="fa-regular fa-rocket"></i>
                </span>
              }
              <i class="fa-regular {{ link.icon.class }} sidenav-link-icon"></i>
              @if (collapsed) {
                <span class="sidenav-link-text ms-1" @fadeInOut>
                  {{ link.translation | translate }}
                </span>
              }
            </a>
          }
        </li>
      }
      @if (isTrial) {
        <li class="sidenav-nav-item">
          <div class="free-trial-container" [ngClass]="{'expanded': collapsed}">
            @if (!collapsed) {
              <div class="sidenav-nav-link free-trial-link">
                <i class="fa-regular fa-rocket sidenav-link-icon"></i>
              </div>
            }
            @if (collapsed) {
              <div class="banner-content d-flex align-items-center flex-column">
                <img
                  height="70px"
                  width="70px"
                  src="/assets/img/png/clicky-success.png"
                  alt=""
                />
                <h1 class="mt-3 banner-title">
                  {{ "sidenav.trial_banner.welcome" | translate }}
                </h1>
                <p class="text-center">
                  {{ "sidenav.trial_banner.trial_available" | translate }}
                  {{ trialDays }}
                  {{
                    (trialDays! > 1
                      ? "sidenav.trial_banner.days"
                      : "sidenav.trial_banner.day"
                    ) | translate
                  }}.
                </p>
                <p class="text-center">
                  {{ "sidenav.trial_banner.trial_desc" | translate }}
                </p>
                <button
                  class="wsm-btn wsm-btn-primary mt-3"
                  type="submit"
                  [routerLink]="['/settings/billing/manage-plan']"
                  data-cy-redirectBilling="redirect-to-billing-btn"
                >
                  {{ "sidenav.trial_banner.call_to_action" | translate }}
                </button>
              </div>
            }
          </div>
        </li>
      }
    </ul>
  </section>
}
