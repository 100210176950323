<div class="d-flex flex-column h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">{{ "shared.dialogs.sharing.title" | translate }}</h1>

      <button
        (click)="closeDialog(false)"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    <p>{{ "shared.dialogs.sharing.description" | translate }}</p>
  </header>

  <mat-dialog-content class="share-link-content">
    <div class="row mx-0">
      <div class="col-12 col-lg-8">
        <label>{{ "shared.dialogs.sharing.title" | translate }}</label>
        <div
          class="wsm-input-group-prepend w-100"
          [style.user-select]="accessCtrl.value ? 'text' : 'none'"
        >
          @if (linkLoading()) {
            <i
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </i>
          }
          @if (isCopied()) {
            <i
              class="fa-solid fa-check wsm-text-success"
              #tooltip="matTooltip"
              matTooltip="{{
                'shared.dialogs.sharing.copied_tooltip' | translate
              }}"
              matTooltipPosition="below"
              matTooltipClass="wsm-tooltip tooltip-below"
            ></i>
          }
          <input
            type="text"
            class="wsm-input me-2 w-100 custom-input"
            placeholder=""
            autocomplete="off"
            [formControl]="linkCtrl"
            [style.padding-right]="
              linkLoading() || isCopied() ? '28px' : '10px'
            "
            (copy)="(accessCtrl.value)"
          />
        </div>
      </div>

      @if (!data.isMobile) {
        <div class="col-12 col-lg-4 mt-3 mt-lg-0 d-flex align-items-end">
          <button
            type="button"
            class="wsm-btn wsm-btn-primary w-100"
            (click)="onCopyLink()"
            [disabled]="linkLoading() || !accessCtrl.value"
          >
            {{ "shared.dialogs.sharing.copy_button" | translate }}
          </button>
        </div>
      }

      <div class="col-12 d-flex align-items-center mt-3">
        <mat-slide-toggle
          class="custom-access-slide"
          [formControl]="accessCtrl"
        >
          <span>{{
            "shared.dialogs.sharing.enable_access_label" | translate
          }}</span>
        </mat-slide-toggle>

        <i
          class="fa-solid fa-circle-question ms-2"
          matTooltip="{{
            'shared.dialogs.sharing.enable_access_info' | translate
          }}"
          matTooltipPosition="below"
          matTooltipClass="wsm-tooltip"
        >
        </i>
      </div>
    </div>
  </mat-dialog-content>

  @if (data.isMobile) {
    <mat-dialog-actions align="end">
      <div class="col-12 col-lg-4 mt-lg-0 d-flex align-items-end">
        <button
          type="button"
          class="wsm-btn wsm-btn-primary w-100"
          (click)="onCopyLink()"
          id="btnShareableLinkCopyToClipboard"
          [disabled]="linkLoading || !accessCtrl.value"
        >
          {{ "shared.dialogs.sharing.copy_button" | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  }
</div>
