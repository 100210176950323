import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  effect,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import {Router, RouterLink} from '@angular/router';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {
  Subscription,
  debounceTime,
  distinctUntilChanged,
  startWith,
  take,
} from 'rxjs';
import {
  AuthenticationService,
  DialogService,
  LoadingService,
  MobileService,
  NotificationService,
} from 'src/app/core/services';
import {ReportService} from 'src/app/core/services/report.service';
import {
  ExportToDialogComponent,
  MessageDialogComponent,
  ReportHistoryDialogComponent,
} from 'src/app/shared/dialogs';
import {
  CustomError,
  Report,
  SearchReportParams,
  UpdateReportPayload,
} from 'src/app/shared/models';
import {CreateReportFromComponent} from '../create-report-from/create-report-from.component';
import {setCharacterEscape} from 'src/app/shared/helpers';
import {AlphanumericDirective} from '../../../../shared/directives/alphanumeric.directive';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {NgClass, NgTemplateOutlet, DatePipe} from '@angular/common';
import {MatProgressBar} from '@angular/material/progress-bar';
import {EditableTitleComponent} from '../../../../shared/components/editable-title/editable-title.component';
import {ProfilePictureComponent} from '../../../../shared/components/profile-picture/profile-picture.component';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {LoadingComponent} from '../../../../shared/components/loading/loading.component';
import {ReportCardComponent} from '../../../../shared/components/report-card/report-card.component';
import {TruncatePipe} from '../../../../shared/pipes/truncate-string.pipe';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';

// [x]: Signals implemented
@Component({
  selector: 'app-reporting-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reporting-list.component.html',
  styleUrls: ['./reporting-list.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    AlphanumericDirective,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgClass,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatSortHeader,
    MatProgressBar,
    EditableTitleComponent,
    RouterLink,
    ProfilePictureComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    LoadingComponent,
    NgTemplateOutlet,
    ReportCardComponent,
    DatePipe,
    TranslateModule,
    TruncatePipe,
  ],
})
export class ReportingListComponent implements OnInit, AfterViewInit {
  isMobile = input<boolean>(false);

  //MatTable
  paginator = viewChild<MatPaginator>('reportPaginator');
  sort = viewChild<MatSort>(MatSort);
  reportListTable = viewChild<MatTable<any>>(MatTable);

  private notificationService = inject(NotificationService);
  private reportService = inject(ReportService);
  public loader = inject(LoadingService);
  private translate = inject(TranslateService);
  public dialog = inject(MatDialog);
  private authService = inject(AuthenticationService);
  private mobileService = inject(MobileService);
  private dialogService = inject(DialogService);
  private router = inject(Router);
  private changeDetectRef = inject(ChangeDetectorRef);
  private authenticationService = inject(AuthenticationService);

  public displayedColumns = signal([
    'favorite',
    'name',
    'companiesLabel',
    'lastModifiedBy',
    'lastModified',
    'actions',
  ]);
  public pageSizeOptions = [5, 10, 20, 50];
  public filterInputControl: UntypedFormControl = new UntypedFormControl('');
  public dataSource: MatTableDataSource<any>;

  callImpersonate = signal<boolean | null>(null);

  // State Variables
  isLoading = signal<boolean>(true);
  isTableLoading = signal<boolean>(true);
  reports = signal<Report[]>([]);
  reportCount = signal<number>(0);
  isExternalUser = signal<boolean>(false);

  //Properties
  subs: Subscription = new Subscription();
  mobilePage = signal<number>(1);
  isFirstLoad = signal<boolean>(true);
  totalReports = signal<number | null>(null);
  updatingName = signal<{[key: string]: boolean}>({});

  constructor() {}

  ngOnInit(): void {
    this.subs.add(
      this.mobileService.scroll$.subscribe((res: boolean): void => {
        if (this.isMobile()) {
          this.mobilePage.update((page) => {
            return (page += 1);
          });
          this.getReports(res);
        }
      })
    );

    this.filterInputControl.valueChanges
      .pipe(startWith({}), debounceTime(500), distinctUntilChanged())
      .subscribe({
        next: () => {
          if (!this.isMobile()) {
            this.paginator()?.firstPage();
          }
          if (this.isMobile()) {
            this.mobilePage.set(1);
          }
          this.getReports();
        },
      });
  }

  ngAfterViewInit(): void {
    this.callImpersonate.set(this.authService?.checkRole(['ROLE_IMPERSONATE']));
    this.isExternalUser.set(this.authService.checkRole(['ROLE_EXTERNAL']));
    this.changeDetectRef.detectChanges();
  }

  getReports(isScrolled?: boolean): void {
    this.isTableLoading.set(!this.isMobile());

    this.reportService.getReports(this.getSearchParams()).subscribe({
      next: (res) => {
        if (res.data.length === 0 && this.mobilePage() !== 1) {
          this.mobilePage.set(1);
        }
        if (this.isFirstLoad()) {
          this.totalReports.set(res.total);
          this.isFirstLoad.set(false);
        }
        this.reports.update((reports) => {
          return !isScrolled ? res.data : [...reports, ...res.data];
        });
        this.dataSource = new MatTableDataSource(this.reports());
        this.reportCount.set(res.total);
        this.isTableLoading.set(false);
        if (this.reports().length === 0 && this.paginator()?.pageIndex !== 0) {
          this.paginator()?.firstPage();
        }
        this.isLoading.set(false);
        this.loader.hide('body');
      },
      error: (err: CustomError) => {
        this.translate
          .get('notifications.general_error')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
        this.isTableLoading.set(false);
        this.isLoading.set(false);
        this.loader.hide('body');
      },
      complete: () => {
        //
      },
    });
  }

  private getSearchParams(): SearchReportParams {
    return {
      sortBy: this.sort()?.active
        ? `${this.sort()?.active} ${this.sort()?.direction}`
        : null,
      filter: `name contains ${setCharacterEscape(this.filterInputControl.value)}`,
      limit: this.isMobile() ? 10 : this.paginator()?.pageSize,
      page: this.isMobile()
        ? this.mobilePage()
        : (this.paginator() as MatPaginator)?.pageIndex + 1,
    } as SearchReportParams;
  }

  onPaginationChange(): void {
    this.getReports();
  }

  onSortChange(): void {
    this.getReports();
  }

  addToFavorites(report: Report): void {
    report.favorite = !report.favorite;
    const payload = {favorite: report.favorite} as UpdateReportPayload;

    this.reportService.updateReport(payload, report.id).subscribe({
      next: () => {
        //
      },
      error: (err) => {
        this.translate
          .get('notifications.general_error')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
      },
    });
  }

  onDeleteReport(reportId): void {
    this.reportService.deleteReport(reportId).subscribe({
      next: () => {
        //
      },
      error: (err) => {
        this.translate
          .get('notifications.general_error')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
      },
      complete: () => {
        this.translate
          .get('reporting.create_report.notification.report_removed')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
        this.getReports();
      },
    });
  }

  onConfirmationRemove(report: Report): void {
    const onCloseActions = (res?): void => {
      if (res) {
        this.onDeleteReport(report.id);
      }
    };

    this.dialogService.openDialog(
      ConfirmationDialogComponent,
      {
        data: {
          title: this.translate.instant(
            'reporting.confirmation_dialog.remove_report.title'
          ),
          content: this.translate.instant(
            'reporting.confirmation_dialog.remove_report.content',
            {reportName: report?.name}
          ),
          isMobile: this.isMobile(),
        },
        width: '45vw',
      },
      onCloseActions.bind(this)
    );
  }

  onCloneDialog(report): void {
    const dialogRef = this.dialog.open(CreateReportFromComponent, {
      data: {
        report: report,
        from: 'report',
      },
      autoFocus: false,
      maxWidth: '100vw',
      width: '45vw',
      height: '82vh',
      disableClose: true,
      position: {
        top: '4rem',
      },
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getReports();
      }
      dialogSub.unsubscribe();
    });
  }

  openReportHistory(report: Report): void {
    this.dialogService.openDialog(ReportHistoryDialogComponent, {
      data: {
        reportId: report.id,
        files: report.fileHistory,
        isMobile: this.isMobile(),
        overrideWidth: true,
      },
      autoFocus: false,
      disableClose: false,
      width: '40vw',
    });
  }

  openExportReport(report: Report): void {
    const dialogRef = this.dialog.open(ExportToDialogComponent, {
      data: {isExport: true, report},
      height: '616px',
      panelClass: 'export-dialog',
      width: '50vw',
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        dialogSub.unsubscribe();
      });
  }

  onShowMoreClients(report: Report): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        title: this.translate.instant('sidenav.links.companies'),
        content: report.companiesLabel,
        isMobile: this.isMobile(),
      },
      width: this.isMobile() ? '100vw' : '45vw',
      maxWidth: this.isMobile() ? '100vw' : '80vw',
      position: this.isMobile() ? {bottom: '0px'} : {},
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.onDeleteReport(report.id);
        }
        dialogSub.unsubscribe();
      },
    });
  }

  onTitleReportChange(event: string, reportId: string): void {
    const idx = this.dataSource.data.findIndex((r) => r.id === reportId);
    this.handleReportinUpdatingNameStatus(reportId, true);
    this.reportService.updateReport({name: event}, reportId).subscribe({
      next: (res) => {
        if (res) {
          this.dataSource.data[idx].name = event;
          this.handleReportinUpdatingNameStatus(reportId, false);
          this.reportListTable()?.renderRows();
          const msgNotification = this.translate.instant(
            'notifications.report_name_updated'
          );
          this.notificationService.success(msgNotification, 5000);
        }
      },
      error: (err) => {
        this.handleReportinUpdatingNameStatus(reportId, false);
        this.notificationService.error(err.error.error_description, 5000);
      },
    });
  }

  private handleReportinUpdatingNameStatus(
    reportId: string,
    status: boolean
  ): void {
    this.updatingName.update((ids) => {
      return {...ids, [reportId]: status};
    });
  }

  goToGetStarted(): void {
    this.router.navigate(['./get-started']);
  }
}
