<header class="mb-4">
  <div
    class="d-flex justify-content-between align-items-start align-items-sm-center"
  >
    <div
      class="d-flex"
      [ngClass]="{
        'flex-row align-items-start': isSignup,
        'flex-column align-items-start': !isSignup,
      }"
    >
      @if (isSignup) {
        <button
          class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon me-2"
          (click)="isSignup = false"
        >
          <i
            class="fa-solid {{
              isMobile ? 'fa-arrow-left' : 'fa-chevron-left'
            }}"
          >
          </i>
        </button>
      }

      <h1 class="h2 m-0 me-4 mb-0 mb-lg-3">
        {{
          (header !== ""
            ? header
            : !isSignup
              ? "shared.dialogs.manage_credentials.manage_connections"
              : "shared.dialogs.manage_credentials.add_service"
          ) | translate
        }}
      </h1>

      @if (!isSignup && isIndividual) {
        <app-sign-in-button
          [service]="data?.service!"
          [isConnecting]="isConnecting"
          [isTokenAuth]="isTokenAuth"
          (addNewAccount)="addNewAccount()"
        />
      }
    </div>

    @if (!isSignup) {
      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    }
  </div>
</header>

<mat-dialog-content
  class="services-content"
  [ngClass]="{
    'mobile-content': isMobile,
    'overflow-hidden': isLoading,
    'overflow-auto': !isLoading,
  }"
>
  @if (!isSignup && (!isMobile || largeMobile)) {
    <div class="table-container">
      <div class="table-header">
        <div class="d-flex justify-content-end mb-3">
          <div class="wsm-input-group-prepend">
            <i class="fa-regular fa-magnifying-glass ps-1"></i>
            <input
              (keyup)="applyFilter($event)"
              type="text"
              class="wsm-input"
              #filterInput
              placeholder="{{
                'reporting.reporting_list.search_placeholder' | translate
              }}"
            />
          </div>
        </div>
      </div>
      <div class="table-content">
        @if (servicesDataSource.data.length !== 0) {
          <table
            mat-table
            [dataSource]="servicesDataSource"
            matSort
            class="table table-borderless wsm-table-header-border"
          >
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{
                  "shared.dialogs.manage_credentials.services_table.platform"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service" class="text-center">
                <img
                  [src]="service.logoSrc"
                  width="25"
                  title="{{ service.type }}"
                  alt="Platform Logo"
                />
              </td>
            </ng-container>
            <ng-container matColumnDef="serviceName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{
                  "shared.dialogs.manage_credentials.services_table.name"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service">
                {{ service.userName ? service.userName : service.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="userEmail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{
                  "shared.dialogs.manage_credentials.services_table.email"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service">
                {{ service.userEmail }}
              </td>
            </ng-container>
            <ng-container matColumnDef="lastUpdated">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{
                  "shared.dialogs.manage_credentials.services_table.last_updated"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service">
                {{ service.lastUpdate }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                {{
                  "shared.dialogs.manage_credentials.services_table.status"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service" class="text-center">
                <div
                  class="status-indicator p-1"
                  [ngClass]="{
                    'active': service.status === 'DONE',
                    'error':
                      service.status === 'ERROR' ||
                      service.status === 'AUTH_ERROR',
                    'in-progress': service.status === 'RUNNING',
                    'queue':
                      service.status === 'QUEUE' ||
                      service.status === 'PENDING',
                  }"
                >
                  @if (service.status === "DONE") {
                    {{ "common.status.done" | translate }}
                  }
                  @if (service.status === "ERROR") {
                    {{ "common.status.error" | translate }}
                  }
                  @if (service.status === "AUTH_ERROR") {
                    {{ "common.status.credential_error" | translate }}
                  }
                  @if (service.status === "RUNNING") {
                    {{ "common.status.running" | translate }}
                  }
                  @if (
                    service.status === "QUEUE" || service.status === "PENDING"
                  ) {
                    {{ "common.status.queue" | translate }}
                  }
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="orgWide">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                {{
                  "shared.dialogs.manage_credentials.services_table.shared"
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let service" class="text-center">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="toggleShared(service)"
                  [disabled]="
                    !currentUser.isAdmin && currentUser.id !== service.owners[0]
                  "
                  [checked]="service.orgWide"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let service" class="action-buttons">
                @if (
                  service.status !== "ERROR" &&
                  service.status !== "AUTH_ERROR" &&
                  (checkOwnership(service) || currentUser.isAdmin)
                ) {
                  <button
                    class="wsm-btn wsm-btn-outline wsm-btn-small w-100 align-self-baseline"
                    [disabled]="
                      service.status === 'RUNNING' ||
                      service.status === 'PENDING'
                    "
                    (click)="refreshAccounts(service)"
                  >
                    {{
                      "shared.dialogs.manage_credentials.refresh_list_button"
                        | translate
                    }}
                  </button>
                }
                @if (
                  service.status !== "ERROR" &&
                  service.status !== "AUTH_ERROR" &&
                  (checkOwnership(service) || currentUser.isAdmin) &&
                  (service.type.startsWith("call") ||
                    service.type.startsWith("semrush"))
                ) {
                  <button
                    class="wsm-btn wsm-btn-outline wsm-btn-small w-100 mt-3"
                    (click)="updateToken(service)"
                  >
                    {{
                      "shared.dialogs.manage_credentials.update_token_button"
                        | translate
                    }}
                  </button>
                }
                @if (
                  service.status === "ERROR" || service.status === "AUTH_ERROR"
                ) {
                  <button
                    (click)="addNewAccount()"
                    class="wsm-btn wsm-btn-outline wsm-btn-small w-100 align-self-baseline"
                  >
                    {{
                      "shared.dialogs.manage_credentials.authenticate_button"
                        | translate
                    }}
                  </button>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let service"
                class="justify-content-center"
              >
                @if (
                  currentUser.isAdmin ||
                  currentUser.isSuperAdmin ||
                  service.owners.includes(currentUser._id)
                ) {
                  <button
                    type="button"
                    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
                    (click)="openConfirmDialog(service._id)"
                  >
                    <i class="fa-regular fa-trash"></i>
                  </button>
                }
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
          </table>
        }
        @if (servicesDataSource.data.length === 0) {
          <div class="wsm-alert-message mt-3">
            <strong>
              {{
                "shared.dialogs.manage_credentials.services_table.no_services"
                  | translate
              }}
            </strong>
          </div>
        }
        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
        ></mat-paginator>
      </div>
    </div>
  }

  @if (!isSignup && isMobile && !largeMobile) {
    <div>
      <div class="wsm-input-group-prepend w-100">
        <i class="fa-regular fa-magnifying-glass ps-1"></i>
        <input
          #searchCards
          (keyup)="applyFilter($event)"
          type="text"
          class="wsm-input mb-3 w-100"
          placeholder="{{
            'reporting.reporting_list.search_placeholder' | translate
          }}"
        />
      </div>
      <app-card-carousel
        [cardData]="cardData"
        [indicatorsOn]="true"
        [indicatorNumber]="3"
        (handledButton)="onCardHandler($event)"
      />

      @if (cardData?.elements?.length === 0 && searchCards?.value) {
        <div class="text-center">
          <span>
            {{
              "shared.dialogs.manage_credentials.services_table.no_connections"
                | translate
            }}
          </span>
        </div>
      }
    </div>
  }

  @if (isSignup) {
    <app-connection-login
      (loadingState)="isLoading = $event"
    ></app-connection-login>
  }

  <!-- Loading -->
  @if (isLoading && !isMobile) {
    <app-loading [generalLoading]="false" [basicLoading]="true" />
  }

  @if (isLoading && isMobile) {
    <app-mobile-loading />
  }
</mat-dialog-content>
