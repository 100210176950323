import {SelectionModel} from '@angular/cdk/collections';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  merge,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import {NotificationService} from 'src/app/core/services';
import {ClientService} from 'src/app/modules/companies/services/client.service';
import {setCharacterEscape} from 'src/app/shared/helpers';
import {IAlert, Report} from 'src/app/shared/models';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgClass, TitleCasePipe } from '@angular/common';
import { LoadingComponent } from '../../../loading/loading.component';

@Component({
    selector: 'app-specific',
    templateUrl: './specific.component.html',
    styleUrls: ['./specific.component.scss'],
    standalone: true,
    imports: [
        MatChipListbox,
        MatChipOption,
        MatIcon,
        MatChipRemove,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatCheckbox,
        MatSortHeader,
        NgClass,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        LoadingComponent,
        TitleCasePipe,
        TranslateModule,
    ],
})
export class SpecificComponent implements OnInit, OnDestroy {
  @Input() report?: Report;
  @Input() alert?: IAlert;
  @Input() conditionsFromWizard?: Array<any> = [];
  @ViewChild(MatSort, {static: true}) sortClient: MatSort;
  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;
  @ViewChild('paginator', {static: true}) paginatorClient: MatPaginator;

  @Output() sendCondition = new EventEmitter<any[]>();
  @Output() loadingState = new EventEmitter<boolean>();
  public loading: boolean;

  // mat table
  public headerLabel: string[] = [''];
  public displayedColumns = ['select', 'name', 'owner', 'status'];
  public pageSizeOptions = [5, 10, 15];
  public listCounter: number;
  public dataSource = new MatTableDataSource<any>();

  public selectionClient = new SelectionModel<any>(true, []);
  public selectedClients: string[] = [];
  public clientPills: {name: string; id: string}[] = [];

  protected onDestroy = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private clientService: ClientService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getClients();
    if (!this.conditionsFromWizard) {
      if (
        this.report?.companiesMatching &&
        this.report.companiesMatching.length > 0 &&
        this.report?.companiesMatching[0].operator === 'in'
      ) {
        this.selectedClients = [...this.report!.companiesMatching[0].value];

        this.sendCondition.emit(this.report.companiesMatching);
      }
    }
    if (this.conditionsFromWizard && this.conditionsFromWizard?.length !== 0) {
      this.selectedClients = [...this.conditionsFromWizard[0].value];
    }
    if (this.alert?.clients && this.alert.clients.length > 0) {
      this.clientPills = [...this.alert.clients];
    }
    if (
      this.report?.selectedClients &&
      this.report.selectedClients.length > 0
    ) {
      this.clientPills = [...this.report.selectedClients];
    }
  }

  getTranslations(): void {
    const headerName$ = this.translate.get(
      'company_selection.section.specific.table.header.name'
    );
    const headerOwner$ = this.translate.get(
      'company_selection.section.specific.table.header.owner'
    );

    combineLatest([headerName$, headerOwner$]).subscribe({
      next: ([headerName, headerOwner]) => {
        this.headerLabel.push(headerName);
        this.headerLabel.push(headerOwner);
      },
    });
  }

  getClients(): void {
    this.loading = true;
    if (!this.filterInput?.nativeElement.value) {
      this.loadingState.emit(true);
    }
    merge(
      this.sortClient?.sortChange,
      this.paginatorClient?.page,
      fromEvent(this.filterInput?.nativeElement, 'keydown')
    )
      .pipe(
        startWith({}),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((evt) => {
          this.loading = true;
          if (!this.filterInput?.nativeElement.value) {
            this.loadingState.emit(true);
          }
          if (evt instanceof KeyboardEvent) {
            this.paginatorClient.firstPage();
          }
          const params = this.buildParams();
          this.selectionClient.clear();
          if (
            this.report &&
            this.report?._id &&
            this.report?.companiesMatching.length !== 0
          ) {
            params['selected[]'] = this.selectedClients;
          }

          if (
            this.alert &&
            this.alert?.id &&
            this.alert?.companiesMatching?.length !== 0
          ) {
            params['selected[]'] = this.selectedClients;
          }

          return this.clientService.getClients(params);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe({
        next: (res) => {
          this.listCounter = res?.total;
          this.dataSource.data = res?.clients;
          if (this.selectedClients?.length > 0) {
            this.selectedClients.forEach((company) => {
              const found = this.dataSource?.data?.find(
                (element) => element._id === company
              );
              if (found) {
                this.selectionClient.select(found);
                !this.report && this.handleAddClientChip(found);
              }
            });
          }

          if (
            this.report &&
            this.report?._id &&
            this.report?.companiesMatching.length !== 0
          ) {
            if (this.report.companiesMatching[0].operator === 'in') {
              if (this.selectedClients?.length > 0) {
                this.selectedClients.forEach((company) => {
                  const found = this.dataSource?.data?.find(
                    (element) => element._id === company
                  );
                  if (found) {
                    this.selectionClient.select(found);
                    this.handleAddClientChip(found);
                  }
                });
              }
            }
          }
          this.loading = false;
          this.loadingState.emit(false);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error(
            err.message ?? 'An error has ocurred. Please contact support.',
            5000
          );
          this.loading = false;
          this.loadingState.emit(false);
        },
      });
  }

  buildParams(): any {
    return {
      sortBy: this.sortClient.active
        ? `${this.sortClient.active} ${this.sortClient.direction}`
        : null,
      filter: `name contains ${setCharacterEscape(this.filterInput.nativeElement.value)}`,
      pageSize: this.paginatorClient.pageSize,
      page: this.paginatorClient.pageIndex + 1,
      callFrom: 'mainClientList',
    };
  }

  selectClient(clientId: string): void {
    this.clientPills = [...this.clientPills];
    const found = this.dataSource?.data?.find(
      (element) => element._id === clientId
    );
    if (this.selectionClient.isSelected(found)) {
      this.selectionClient.toggle(found);
      this.selectedClients.splice(this.selectedClients.indexOf(clientId), 1);
      this.clientPills.splice(
        this.clientPills.findIndex((el) => el.id === clientId),
        1
      );
    } else {
      this.selectionClient.toggle(found);
      this.selectedClients.push(clientId);
      this.handleAddClientChip(found);
    }

    const conditions = this.buildConditions();
    this.sendCondition.emit(conditions);
  }

  removeClientChip(client: any): void {
    const found = this.dataSource?.data?.find(
      (element) => element._id === client.id
    );
    this.selectionClient.toggle(found);
    this.selectedClients.splice(this.selectedClients.indexOf(client.id), 1);
    this.clientPills.splice(
      this.clientPills.findIndex((el) => el.id === client.id),
      1
    );

    const conditions = this.buildConditions();
    this.sendCondition.emit(conditions);
  }

  handleAddClientChip(client: any): void {
    if (this.clientPills && this.clientPills.length === 0) {
      this.clientPills.push({name: client.name, id: client._id});
    }
    if (this.clientPills && this.clientPills.length !== 0) {
      this.clientPills = [
        ...this.clientPills,
        {id: client.id, name: client.name},
      ];
      const clearArray = this.clientPills.reduce(
        (acc, value) => {
          if (acc.length === 0) {
            acc = [value];
          }
          if (acc.length > 0) {
            const isDistinct = acc.every((el) => el.id !== value.id);
            if (isDistinct) {
              acc = [...acc, value];
            }
          }
          return acc;
        },
        <any>[]
      );
      this.clientPills = [...clearArray];
    }
  }
  buildConditions(): any[] {
    const companyIds = this.selectedClients.filter(
      (el, i: number, a) => i === a.indexOf(el)
    );

    return companyIds.length > 0
      ? [{field: 'id', operator: 'in', value: companyIds}]
      : [];
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
