<!-- Desktop Report List -->
<div [hidden]="isMobile() || totalReports() === 0" class="table-container mb-2">
  <div class="table-header d-flex justify-content-between">
    <h5>{{ "reporting.reporting_list.main_title" | translate }}</h5>
    <div class="wsm-input-group-prepend">
      <i class="fa-regular fa-magnifying-glass ps-1"></i>
      <input
        [formControl]="filterInputControl"
        appAlphanumeric
        class="wsm-input"
        placeholder="{{
          'reporting.reporting_list.search_placeholder' | translate
        }}"
        type="text"
        id="search-client-input"
      />
    </div>
  </div>
  <div
    [ngClass]="{'table-fake-content': reports().length === 0}"
    class="table-container table-content mb-3 pt-3"
  >
    <table
      #reportListTable
      mat-table
      [hidden]="reports().length === 0"
      [dataSource]="dataSource"
      (matSortChange)="onSortChange()"
      class="table table-borderless wsm-table-header-border"
      matSort
      matSortActive="lastModifiedDate"
      matSortDirection="desc"
    >
      <!--Favorite Column -->
      <ng-container matColumnDef="favorite">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          class="d-flex align-items-center justify-content-end gap-3"
          mat-cell
          *matCellDef="let element"
        >
          <button
            (click)="addToFavorites(element)"
            class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
          >
            <i
              [ngClass]="{
                'fa-light': !element.favorite,
                'fa-solid star-fill-color': element.favorite,
              }"
              class="fa-star"
            ></i>
          </button>
          <span
            [ngClass]="{
              'landscape': element.orientation === 'horizontal',
              'portrait': element.orientation !== 'horizontal',
            }"
            class="layout-icon"
          >
            @if (element.orientation === "horizontal") {
              <i class="wsm-text-grayed fak fa-landscape-report"></i>
            }
            @if (element.orientation !== "horizontal") {
              <i class="wsm-text-grayed fak fa-portrait-report"></i>
            }
          </span>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "reporting.reporting_list.report_name_column" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          @if (updatingName()[element.id]) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          }
          @if (!updatingName()[element.id]) {
            <app-editable-title
              [titleContent]="element.name"
              [editMode]="true"
              [useAs]="'body'"
              [inputSize]="'w-100'"
              (titleSave)="onTitleReportChange($event, element.id)"
              class="w-100"
            >
              <ng-template>
                <a
                  [routerLink]="['/reports/' + element.id + '/edit']"
                  fragment="page-1"
                  class="text-decoration-none"
                >
                  {{
                    element.name.length < 54
                      ? element.name
                      : element.name.substring(0, 54) + "..."
                  }}
                </a>
              </ng-template>
            </app-editable-title>
          }
        </td>
      </ng-container>

      <!-- Clients Column -->
      <ng-container matColumnDef="companiesLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "reporting.reporting_list.companies_column" | translate }}
        </th>
        <td mat-cell *matCellDef="let report">
          @if (report.companiesLabel?.length! > 45) {
            <span>{{ report.companiesLabel! | truncate: 45 }}</span>
            <button
              class="wsm-btn wsm-btn-link ms-2"
              (click)="$event.stopPropagation(); onShowMoreClients(report)"
            >
              {{ "reporting.reporting_list.show_more_clients" | translate }}
            </button>
          } @else {
            <span>{{ report.companiesLabel }}</span>
          }
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="lastModifiedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "reporting.reporting_list.last_modified_by" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center">
            <app-profile-picture
              [createdBy]="element?.lastModifiedBy"
            ></app-profile-picture>
            <span class="ms-3">{{ element?.lastModifiedBy?.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Last Modified Column -->
      <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "reporting.reporting_list.last_modified_column" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.lastModifiedDate ? (element.lastModifiedDate | date) : ""
          }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "reporting.reporting_list.actions_column" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          @if (!isExternalUser()) {
            <button
              [matMenuTriggerFor]="menu"
              class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon me-2"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
          }
          <mat-menu #menu="matMenu">
            <button (click)="onCloneDialog(element)" mat-menu-item>
              {{ "reporting.reporting_list.clone_action" | translate }}
            </button>
            @if (callImpersonate()) {
              <button (click)="openExportReport(element)" mat-menu-item>
                {{ "reporting.reporting_list.export_to_action" | translate }}
              </button>
            }
            <button (click)="onConfirmationRemove(element)" mat-menu-item>
              {{ "reporting.reporting_list.delete_action" | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
    </table>

    <mat-paginator
      #reportPaginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="5"
      [hidden]="reports().length === 0"
      [length]="reportCount()"
      aria-label="Select page"
      (page)="onPaginationChange()"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
    @if (dataSource?.data?.length === 0) {
      <div class="wsm-alert-message mt-3">
        <strong>
          {{
            "reporting.reporting_list.no_reports_found_label" | translate
          }}</strong
        >
      </div>
    }
    @if (isTableLoading()) {
      <app-loading [generalLoading]="true" [isTable]="true"> </app-loading>
    }
  </div>
</div>

@if (!isMobile() && totalReports() === 0 && !isTableLoading()) {
  <ng-template [ngTemplateOutlet]="relaunchOnboardingBox"></ng-template>
}

<!-- Mobile Report List -->
<div [hidden]="!isMobile()" class="report-cards-container">
  <header [ngClass]="{'sticky-top custom-sticky-top': isMobile()}">
    <h4>{{ "reporting.reporting_list.main_title" | translate }}</h4>
    <section class="d-flex align-items-start">
      <!-- Search Input -->
      <div
        class="wsm-input-group-prepend w-100"
        [ngClass]="{'custom-search mb-3': isMobile()}"
      >
        <i class="fa-regular fa-search ps-1"></i>
        <input
          [formControl]="filterInputControl"
          appAlphanumeric
          type="text"
          class="wsm-input w-100"
          placeholder="{{
            'reporting.reporting_list.search_placeholder' | translate
          }}"
          id="search-client-input-mov"
        />
      </div>
    </section>
  </header>

  <div class="report-card-grid">
    @for (report of reports(); track report.id) {
      <app-report-card
        [isMobile]="isMobile()"
        [report]="report"
        [favoriteControl]="true"
        (reportDeleted)="onConfirmationRemove($event)"
        (addedToFavorites)="addToFavorites($event)"
        (reportHistoryOpened)="openReportHistory($event)"
        (showMoreClients)="onShowMoreClients($event)"
      >
      </app-report-card>
    }
  </div>

  @if (reports()?.length === 0) {
    <div class="wsm-alert-message mt-3">
      <strong>
        {{
          "reporting.reporting_list.no_reports_found_label" | translate
        }}</strong
      >
    </div>
  }
</div>

@if (isMobile() && isTableLoading()) {
  <div class="w-100 mt-2 text-center">
    <div class="pe-4">
      <span
        class="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{ "reporting.reportToolbar.loading_label" | translate }}
    </div>
  </div>
}

<ng-template #relaunchOnboardingBox>
  <div class="d-flex flex-column align-items-center p-5 gap-3">
    <img
      class="avatar-getstarted"
      src="../../../../../assets/img/png/avatar_2.png"
      alt="avatar_2"
    />
    <p>
      <span>{{ "get_started.relaunch_question" | translate }}</span
      >&nbsp;
      <strong>
        <span>{{ "get_started.relaunch_answer" | translate }}</span>
      </strong>
    </p>
    <button (click)="goToGetStarted()" class="wsm-btn wsm-btn-primary">
      {{ "get_started.relaunch_cta" | translate }}
    </button>
  </div>
</ng-template>
