import {Component, input, ViewChild} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import userflow from 'userflow.js';
import {NgClass} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  standalone: true,
  imports: [TranslateModule, MatMenu, MatMenuTrigger, NgClass, MatTooltip],
})
export class HelpComponent {
  public idHelp = input<string>('');
  public helpTextTranslation = input<string>('');
  public bigButton = input<boolean>(false);
  public mediumButton = input<boolean>(false);
  public customClass = input<string>('');
  public tooltipText = input<string>('');

  @ViewChild('menu') menu: MatMenu;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  public onClosse(): void {
    this.menuTrigger?.closeMenu();
  }

  public onLaunchHelp(): void {
    this.menuTrigger?.closeMenu();
    userflow?.start(this.idHelp()).then();
  }
}
